  /* App */

  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #09d3ac;
  }
  
  /* all */
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* carousel */

  .carousel-wrapper {
    max-width: 600px;
    margin: 20px auto;
    padding: 0 20px
  }
  
  /* swiper */
  
  .main-swiper {
    margin: 40px 0;
  }
  
  .swiper {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 0px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 200px;
  }
  
  .swiper-slide img {
    display: block;
    width: 96%;
  }
  